import React from 'react';
import ITPwordmarkLogo from './logo/ITP-wordmark_White.png';

const LogoFull = () => {
  return (
    <img
      src={ITPwordmarkLogo}
      alt="Logo"
      style={{ width: '100px', height: 'auto' }}
    />
  );
};

export default LogoFull;
