import React from 'react';
import ITPbrandmarkLogo from './logo/ITP-brandmark_White.png';

const LogoIcon = () => {
  return (
    <img
      src={ITPbrandmarkLogo}
      alt="Logo"
      style={{ width: '30px', height: 'auto' }}
    />
  );
};

export default LogoIcon;
