import {
  createBaseThemeOptions,
  createUnifiedTheme,
  defaultTypography,
  palettes,
} from '@backstage/theme';
import dmSerifDisplay from './assets/DMSerifDisplay-Regular.ttf';

const DMSerifDisplay = {
  fontFamily: 'DM Serif Display',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 300,
  src: `
    local('DM-Serif-Display'),
    url(${dmSerifDisplay}) format('ttf'),
  `,
};

export const lightTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.light,
      primary: {
        light: '#c6d6db',
        main: '#0000D0',
        dark: '#000000',
      },
      background: {
        default: '#ffffff',
        paper: '#f5f3f0',
      },
      error: {
        main: '#ff5722',
      },
      warning: {
        main: '#8f5e15',
      },
      info: {
        main: '#22899f',
      },
      success: {
        main: '#30d4aa',
      },
      banner: {
        info: '#34548a',
        error: '#8c4351',
        text: '#343b58',
        link: '#565a6e',
      },
      navigation: {
        background: '#000000',
        indicator: '#8f5e15',
        color: '#d5d6db',
        selectedColor: '#ffffff',
      },
    },
  }),
  fontFamily: 'Flama',
  typography: {
    ...defaultTypography,
    h1: {
      fontSize: 54,
      fontWeight: 600,
      marginBottom: 10,
      fontFamily: 'DM-Serif-Display',
    },
  },
  defaultPageTheme: 'home',
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': [DMSerifDisplay],
      },
    },
    BackstageHeader: {
      styleOverrides: {
        header: () => ({
          width: 'auto',
          boxShadow: 'none',
          backgroundImage: 'none',
          backgroundColor: '#000000',
        }),
      },
    },
  },
});
